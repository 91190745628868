
import React from "react";
import { Input } from "@/components/ui/input";
import { ContactInfo } from "@/types/invoice";

interface ContactFormProps {
  title: string;
  info: ContactInfo;
  setInfo: (info: ContactInfo) => void;
}

export const ContactForm: React.FC<ContactFormProps> = ({ title, info, setInfo }) => {
  return (
    <div className="space-y-1">
      <h3 className="text-sm font-semibold text-gray-800 mb-2">{title}</h3>
      <div className="text-sm space-y-1">
        <Input
          placeholder="Name"
          value={info.name}
          onChange={(e) => setInfo({ ...info, name: e.target.value })}
          className="h-7 text-sm border-dashed"
        />
        <Input
          placeholder="Street Address"
          value={info.address}
          onChange={(e) => setInfo({ ...info, address: e.target.value })}
          className="h-7 text-sm border-dashed"
        />
        <div className="grid grid-cols-3 gap-2">
          <Input
            placeholder="City"
            value={info.city}
            onChange={(e) => setInfo({ ...info, city: e.target.value })}
            className="h-7 text-sm border-dashed"
          />
          <Input
            placeholder="State"
            value={info.state}
            onChange={(e) => setInfo({ ...info, state: e.target.value })}
            className="h-7 text-sm border-dashed"
          />
          <Input
            placeholder="ZIP"
            value={info.zipCode}
            onChange={(e) => setInfo({ ...info, zipCode: e.target.value })}
            className="h-7 text-sm border-dashed"
          />
        </div>
        <Input
          placeholder="Phone"
          value={info.phone}
          onChange={(e) => setInfo({ ...info, phone: e.target.value })}
          className="h-7 text-sm border-dashed"
        />
        <Input
          placeholder="Email"
          value={info.email}
          onChange={(e) => setInfo({ ...info, email: e.target.value })}
          className="h-7 text-sm border-dashed"
        />
      </div>
    </div>
  );
};

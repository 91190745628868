
import React from "react";
import { ContactInfo, LineItem, CurrencyCode } from "@/types/invoice";
import { LogoUploader } from "./LogoUploader";
import { InvoiceHeader } from "./InvoiceHeader";
import { ContactForm } from "./ContactForm";
import { LineItemsTable } from "./LineItemsTable";
import { InvoiceSummary } from "./InvoiceSummary";
import { NotesAndTerms } from "./NotesAndTerms";

interface EditablePreviewProps {
  logo: string | null;
  setLogo: (logo: string | null) => void;
  logoSize: number;
  setLogoSize: (size: number) => void;
  invoiceNumber: string;
  setInvoiceNumber: (value: string) => void;
  invoiceDate: string;
  setInvoiceDate: (value: string) => void;
  dueDate: string;
  setDueDate: (value: string) => void;
  businessInfo: ContactInfo;
  setBusinessInfo: (info: ContactInfo) => void;
  clientInfo: ContactInfo;
  setClientInfo: (info: ContactInfo) => void;
  items: LineItem[];
  setItems: (items: LineItem[]) => void;
  notes: string;
  setNotes: (value: string) => void;
  terms: string;
  setTerms: (value: string) => void;
  taxRate: number;
  setTaxRate: (value: number) => void;
  amountPaid: number;
  setAmountPaid: (value: number) => void;
  currency: CurrencyCode;
  setCurrency: (value: CurrencyCode) => void;
}

export const EditablePreview: React.FC<EditablePreviewProps> = ({
  logo,
  setLogo,
  logoSize,
  setLogoSize,
  invoiceNumber,
  setInvoiceNumber,
  invoiceDate,
  setInvoiceDate,
  dueDate,
  setDueDate,
  businessInfo,
  setBusinessInfo,
  clientInfo,
  setClientInfo,
  items,
  setItems,
  notes,
  setNotes,
  terms,
  setTerms,
  taxRate,
  setTaxRate,
  amountPaid,
  setAmountPaid,
  currency,
  setCurrency,
}) => {
  return (
    <div className="bg-white p-6 md:p-8 max-w-4xl mx-auto shadow-sm border rounded-md">
      <div className="flex flex-col md:flex-row md:justify-between md:items-start mb-6">
        <LogoUploader 
          logo={logo} 
          setLogo={setLogo} 
          logoSize={logoSize} 
          setLogoSize={setLogoSize} 
        />
        <InvoiceHeader 
          invoiceNumber={invoiceNumber}
          setInvoiceNumber={setInvoiceNumber}
          invoiceDate={invoiceDate}
          setInvoiceDate={setInvoiceDate}
          dueDate={dueDate}
          setDueDate={setDueDate}
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        <ContactForm 
          title="From:" 
          info={businessInfo} 
          setInfo={setBusinessInfo} 
        />
        <ContactForm 
          title="Bill To:" 
          info={clientInfo} 
          setInfo={setClientInfo} 
        />
      </div>

      <LineItemsTable 
        items={items} 
        setItems={setItems}
        currency={currency}
      />

      <InvoiceSummary 
        items={items}
        taxRate={taxRate}
        setTaxRate={setTaxRate}
        amountPaid={amountPaid}
        setAmountPaid={setAmountPaid}
        currency={currency}
        setCurrency={setCurrency}
      />

      <NotesAndTerms 
        notes={notes}
        setNotes={setNotes}
        terms={terms}
        setTerms={setTerms}
      />
    </div>
  );
};


import React from "react";
import { ContactInfo, LineItem, CurrencyCode } from "@/types/invoice";
import { formatAddress, formatCurrency } from "@/utils/invoice";
import {
  calculateSubtotal,
  calculateTax,
  calculateTotal,
  calculateBalanceDue
} from "@/utils/invoice";

interface PdfPreviewProps {
  logo: string | null;
  logoSize: number;
  invoiceNumber: string;
  invoiceDate: string;
  dueDate: string;
  businessInfo: ContactInfo;
  clientInfo: ContactInfo;
  items: LineItem[];
  notes: string;
  terms: string;
  taxRate: number;
  amountPaid: number;
  currency: CurrencyCode;
}

export const PdfPreview: React.FC<PdfPreviewProps> = ({
  logo,
  logoSize,
  invoiceNumber,
  invoiceDate,
  dueDate,
  businessInfo,
  clientInfo,
  items,
  notes,
  terms,
  taxRate,
  amountPaid,
  currency,
}) => {
  const subtotal = calculateSubtotal(items);
  const tax = calculateTax(subtotal, taxRate);
  const total = calculateTotal(subtotal, tax);
  const balanceDue = calculateBalanceDue(total, amountPaid);
  
  return (
    <div className="bg-white p-5 max-w-4xl mx-auto text-xs">
      <div className="flex justify-between items-start mb-5">
        <div>
          {logo && (
            <img 
              src={logo} 
              alt="Business Logo" 
              className="max-w-[180px] object-contain"
              style={{ width: `${logoSize}%` }}
              crossOrigin="anonymous"
            />
          )}
        </div>
        <div className="text-right min-w-[180px]">
          <h1 className="text-lg font-bold text-gray-800">INVOICE</h1>
          <p className="text-gray-600 text-xs">#{invoiceNumber || 'Draft'}</p>
          <div className="mt-1 text-xs text-gray-600">
            <p><span className="font-bold">Invoice Date:</span> {invoiceDate}</p>
            <p><span className="font-bold">Due Date:</span> {dueDate}</p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-5 mb-5">
        <div className="space-y-0.5">
          <h3 className="font-semibold text-gray-800 text-xs">From:</h3>
          <div className="text-gray-600 text-xs">
            <p className="font-medium">{businessInfo.name}</p>
            <p>{businessInfo.address}</p>
            <p>{formatAddress(businessInfo)}</p>
            <p>{businessInfo.phone}</p>
            <p>{businessInfo.email}</p>
          </div>
        </div>
        <div className="space-y-0.5">
          <h3 className="font-semibold text-gray-800 text-xs">Bill To:</h3>
          <div className="text-gray-600 text-xs">
            <p className="font-medium">{clientInfo.name}</p>
            <p>{clientInfo.address}</p>
            <p>{formatAddress(clientInfo)}</p>
            <p>{clientInfo.phone}</p>
            <p>{clientInfo.email}</p>
          </div>
        </div>
      </div>

      <table className="w-full mb-5">
        <thead>
          <tr className="border-b border-gray-200">
            <th className="py-1.5 text-left font-semibold text-gray-800 text-xs">Description</th>
            <th className="py-1.5 text-right font-semibold text-gray-800 text-xs">Quantity</th>
            <th className="py-1.5 text-right font-semibold text-gray-800 text-xs">Rate</th>
            <th className="py-1.5 text-right font-semibold text-gray-800 text-xs">Amount</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={index} className="border-b border-gray-100">
              <td className="py-1.5 text-gray-600 text-xs">{item.description}</td>
              <td className="py-1.5 text-right text-gray-600 text-xs">{item.quantity}</td>
              <td className="py-1.5 text-right text-gray-600 text-xs">{formatCurrency(item.rate, currency)}</td>
              <td className="py-1.5 text-right text-gray-600 text-xs">
                {formatCurrency(item.quantity * item.rate, currency)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="border-t border-gray-200 pt-2">
        <div className="flex justify-end">
          <div className="w-52">
            <div className="space-y-0.5">
              <div className="flex justify-between text-gray-600 text-xs">
                <span>Subtotal:</span>
                <span className="font-medium">{formatCurrency(subtotal, currency)}</span>
              </div>
              <div className="flex justify-between text-gray-600 text-xs">
                <span>Tax ({taxRate}%):</span>
                <span className="font-medium">{formatCurrency(tax, currency)}</span>
              </div>
              <div className="flex justify-between text-xs font-semibold text-gray-800">
                <span>Total:</span>
                <span>{formatCurrency(total, currency)}</span>
              </div>
              <div className="flex justify-between text-gray-600 text-xs">
                <span>Amount Paid:</span>
                <span className="font-medium">{formatCurrency(amountPaid, currency)}</span>
              </div>
              <div className="flex justify-between text-xs font-bold text-gray-800">
                <span>Balance Due:</span>
                <span>{formatCurrency(balanceDue, currency)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {(notes || terms) && (
        <div className="mt-5 pt-2 border-t border-gray-200">
          <div className="grid grid-cols-2 gap-5">
            {notes && (
              <div>
                <h3 className="font-semibold text-gray-800 mb-0.5 text-xs">Notes</h3>
                <p className="text-gray-600 text-xs">{notes}</p>
              </div>
            )}
            {terms && (
              <div>
                <h3 className="font-semibold text-gray-800 mb-0.5 text-xs">Terms</h3>
                <p className="text-gray-600 text-xs">{terms}</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

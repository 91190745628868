
import React from "react";
import { ContactInfo, LineItem, CurrencyCode } from "@/types/invoice";
import { EditablePreview } from "./EditablePreview";
import { PdfPreview } from "./PdfPreview";

interface InvoicePreviewProps {
  invoiceRef: React.RefObject<HTMLDivElement>;
  logo: string | null;
  setLogo: (logo: string | null) => void;
  logoSize: number;
  setLogoSize: (size: number) => void;
  invoiceNumber: string;
  setInvoiceNumber: (value: string) => void;
  invoiceDate: string;
  setInvoiceDate: (value: string) => void;
  dueDate: string;
  setDueDate: (value: string) => void;
  businessInfo: ContactInfo;
  setBusinessInfo: (info: ContactInfo) => void;
  clientInfo: ContactInfo;
  setClientInfo: (info: ContactInfo) => void;
  items: LineItem[];
  setItems: (items: LineItem[]) => void;
  notes: string;
  setNotes: (value: string) => void;
  terms: string;
  setTerms: (value: string) => void;
  taxRate: number;
  setTaxRate: (value: number) => void;
  amountPaid: number;
  setAmountPaid: (value: number) => void;
  currency: CurrencyCode;
  setCurrency: (value: CurrencyCode) => void;
}

export const InvoicePreview: React.FC<InvoicePreviewProps> = (props) => {
  const {
    invoiceRef,
    logo,
    logoSize,
    invoiceNumber,
    invoiceDate,
    dueDate,
    businessInfo,
    clientInfo,
    items,
    notes,
    terms,
    taxRate,
    amountPaid,
    currency,
  } = props;

  return (
    <>
      <div className="block">
        <EditablePreview {...props} />
      </div>
      <div className="hidden">
        <div ref={invoiceRef}>
          <PdfPreview
            logo={logo}
            logoSize={logoSize}
            invoiceNumber={invoiceNumber}
            invoiceDate={invoiceDate}
            dueDate={dueDate}
            businessInfo={businessInfo}
            clientInfo={clientInfo}
            items={items}
            notes={notes}
            terms={terms}
            taxRate={taxRate}
            amountPaid={amountPaid}
            currency={currency}
          />
        </div>
      </div>
    </>
  );
};


import React from "react";
import { Input } from "@/components/ui/input";

interface InvoiceHeaderProps {
  invoiceNumber: string;
  setInvoiceNumber: (value: string) => void;
  invoiceDate: string;
  setInvoiceDate: (value: string) => void;
  dueDate: string;
  setDueDate: (value: string) => void;
}

export const InvoiceHeader: React.FC<InvoiceHeaderProps> = ({
  invoiceNumber,
  setInvoiceNumber,
  invoiceDate,
  setInvoiceDate,
  dueDate,
  setDueDate,
}) => {
  return (
    <div className="text-left md:text-right min-w-[200px] mt-4 md:mt-0">
      <h1 className="text-xl font-bold text-gray-800 mb-1">INVOICE</h1>
      <div className="flex items-center text-sm">
        <span className="mr-1">#</span>
        <Input
          value={invoiceNumber}
          onChange={(e) => setInvoiceNumber(e.target.value)}
          placeholder="Invoice Number"
          className="h-7 text-sm border-dashed"
        />
      </div>
      <div className="mt-2 space-y-1 text-sm">
        <div className="flex items-center">
          <span className="font-medium mr-2 w-24 text-right">Invoice Date:</span>
          <Input
            type="date"
            value={invoiceDate}
            onChange={(e) => setInvoiceDate(e.target.value)}
            className="h-7 text-sm border-dashed"
          />
        </div>
        <div className="flex items-center">
          <span className="font-medium mr-2 w-24 text-right">Due Date:</span>
          <Input
            type="date"
            value={dueDate}
            onChange={(e) => setDueDate(e.target.value)}
            className="h-7 text-sm border-dashed"
          />
        </div>
      </div>
    </div>
  );
};

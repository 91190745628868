
import React, { useRef } from "react";
import { Button } from "@/components/ui/button";
import { Slider } from "@/components/ui/slider";
import { Upload, X } from "lucide-react";

interface LogoUploaderProps {
  logo: string | null;
  setLogo: (logo: string | null) => void;
  logoSize: number;
  setLogoSize: (size: number) => void;
}

export const LogoUploader: React.FC<LogoUploaderProps> = ({
  logo,
  setLogo,
  logoSize,
  setLogoSize,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleLogoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogo(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveLogo = () => {
    setLogo(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <div className="space-y-2">
      {logo ? (
        <div className="space-y-2">
          <div className="relative max-w-[180px]">
            <img 
              src={logo} 
              alt="Business Logo" 
              className="max-w-[180px] object-contain"
              style={{ width: `${logoSize}%` }}
              crossOrigin="anonymous"
            />
            <Button
              type="button"
              variant="ghost"
              size="icon"
              className="absolute top-0 right-0 h-6 w-6 bg-white rounded-full shadow-sm hover:bg-gray-100"
              onClick={handleRemoveLogo}
            >
              <X className="h-3 w-3" />
            </Button>
          </div>
          <Slider
            value={[logoSize]}
            onValueChange={(value) => setLogoSize(value[0])}
            min={20}
            max={100}
            step={1}
            className="w-[180px]"
          />
        </div>
      ) : (
        <Button
          type="button"
          variant="outline"
          onClick={() => fileInputRef.current?.click()}
          className="text-sm h-8"
        >
          <Upload className="w-3 h-3 mr-1" />
          Upload Logo
        </Button>
      )}
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleLogoUpload}
        accept="image/*"
        className="hidden"
      />
    </div>
  );
};


import { ContactInfo, CurrencyCode, CURRENCIES } from "@/types/invoice";

export const formatAddress = (info: ContactInfo) => {
  const cityState = [
    info.city,
    info.state ? (info.city ? `, ${info.state}` : info.state) : ''
  ].filter(Boolean).join('');
  
  return cityState + (info.zipCode ? ' ' + info.zipCode : '');
};

export const calculateSubtotal = (items: { quantity: number; rate: number }[]) => {
  return items.reduce((sum, item) => sum + item.quantity * item.rate, 0);
};

export const calculateTax = (subtotal: number, taxRate: number) => {
  return subtotal * (taxRate / 100);
};

export const calculateTotal = (subtotal: number, tax: number) => {
  return subtotal + tax;
};

export const calculateBalanceDue = (total: number, amountPaid: number) => {
  return total - amountPaid;
};

export const formatCurrency = (amount: number, currencyCode: CurrencyCode = "USD") => {
  const currency = CURRENCIES[currencyCode];
  
  // Special case for currencies that don't use decimal places
  if (currencyCode === "JPY") {
    return `${currency.symbol}${Math.round(amount)}`;
  }
  
  // Special case for currencies where the symbol comes after the amount
  if (currencyCode === "SEK") {
    return `${amount.toFixed(2)} ${currency.symbol}`;
  }
  
  return `${currency.symbol}${amount.toFixed(2)}`;
};


import React from "react";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { LineItem, CurrencyCode, CURRENCIES } from "@/types/invoice";
import {
  calculateSubtotal,
  calculateTax,
  calculateTotal,
  calculateBalanceDue,
  formatCurrency
} from "@/utils/invoice";

interface InvoiceSummaryProps {
  items: LineItem[];
  taxRate: number;
  setTaxRate: (value: number) => void;
  amountPaid: number;
  setAmountPaid: (value: number) => void;
  currency: CurrencyCode;
  setCurrency: (value: CurrencyCode) => void;
}

export const InvoiceSummary: React.FC<InvoiceSummaryProps> = ({
  items,
  taxRate,
  setTaxRate,
  amountPaid,
  setAmountPaid,
  currency,
  setCurrency
}) => {
  const subtotal = calculateSubtotal(items);
  const tax = calculateTax(subtotal, taxRate);
  const total = calculateTotal(subtotal, tax);
  const balanceDue = calculateBalanceDue(total, amountPaid);

  return (
    <div className="border-t border-gray-200 pt-4">
      <div className="flex justify-end">
        <div className="w-full md:w-64">
          <div className="space-y-2">
            <div className="flex justify-between mb-2 items-center">
              <span className="text-sm text-gray-600">Currency:</span>
              <Select
                value={currency}
                onValueChange={(value) => setCurrency(value as CurrencyCode)}
              >
                <SelectTrigger className="w-24 h-8 text-xs">
                  <SelectValue placeholder="Select" />
                </SelectTrigger>
                <SelectContent className="max-h-60">
                  {Object.entries(CURRENCIES).map(([code, currencyInfo]) => (
                    <SelectItem key={code} value={code}>
                      {currencyInfo.symbol} {code}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="flex justify-between text-sm text-gray-600">
              <span>Subtotal:</span>
              <span className="font-medium">{formatCurrency(subtotal, currency)}</span>
            </div>
            <div className="flex justify-between text-sm text-gray-600 items-center">
              <div className="flex items-center">
                <span className="mr-1">Tax</span>
                <Input
                  type="number"
                  value={taxRate}
                  onChange={(e) => setTaxRate(parseFloat(e.target.value) || 0)}
                  className="w-12 h-6 text-xs text-center border-dashed mx-1"
                />
                <span>%:</span>
              </div>
              <span className="font-medium">{formatCurrency(tax, currency)}</span>
            </div>
            <div className="flex justify-between text-base font-semibold text-gray-800">
              <span>Total:</span>
              <span>{formatCurrency(total, currency)}</span>
            </div>
            <div className="flex justify-between text-sm text-gray-600 items-center">
              <span>Amount Paid:</span>
              <Input
                type="number"
                value={amountPaid}
                onChange={(e) => setAmountPaid(parseFloat(e.target.value) || 0)}
                className="w-24 h-6 text-xs text-right border-dashed"
              />
            </div>
            <div className="flex justify-between text-base font-bold text-gray-800">
              <span>Balance Due:</span>
              <span>{formatCurrency(balanceDue, currency)}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


import React from "react";
import { Input } from "@/components/ui/input";

interface NotesAndTermsProps {
  notes: string;
  setNotes: (value: string) => void;
  terms: string;
  setTerms: (value: string) => void;
}

export const NotesAndTerms: React.FC<NotesAndTermsProps> = ({
  notes,
  setNotes,
  terms,
  setTerms
}) => {
  return (
    <div className="mt-6 pt-4 border-t border-gray-200">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <h3 className="text-sm font-semibold text-gray-800 mb-1">Notes</h3>
          <Input
            placeholder="Notes for client"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            className="h-7 text-sm border-dashed"
          />
        </div>
        <div>
          <h3 className="text-sm font-semibold text-gray-800 mb-1">Terms</h3>
          <Input
            placeholder="Payment terms"
            value={terms}
            onChange={(e) => setTerms(e.target.value)}
            className="h-7 text-sm border-dashed"
          />
        </div>
      </div>
    </div>
  );
};

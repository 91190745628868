
import React from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Trash2 } from "lucide-react";
import { LineItem, CurrencyCode } from "@/types/invoice";
import { formatCurrency } from "@/utils/invoice";

interface LineItemsTableProps {
  items: LineItem[];
  setItems: (items: LineItem[]) => void;
  currency: CurrencyCode;
}

export const LineItemsTable: React.FC<LineItemsTableProps> = ({
  items,
  setItems,
  currency
}) => {
  const handleAddItem = () => {
    setItems([...items, { description: "", quantity: 1, rate: 0 }]);
  };

  const handleRemoveItem = (index: number) => {
    setItems(items.filter((_, i) => i !== index));
  };

  const handleItemChange = (
    index: number,
    field: keyof LineItem,
    value: string | number
  ) => {
    const newItems = [...items];
    
    if (field === "quantity" || field === "rate") {
      // For number fields, parse as number or default to 0
      newItems[index] = {
        ...newItems[index],
        [field]: typeof value === "string" ? parseFloat(value) || 0 : value,
      };
    } else {
      // For string fields (like description), use the string value
      newItems[index] = {
        ...newItems[index],
        [field]: value.toString(),
      };
    }
    
    setItems(newItems);
  };

  return (
    <div className="mb-6">
      <div>
        <table className="w-full mb-6">
          <thead>
            <tr className="border-b border-gray-200">
              <th className="py-2 text-left text-sm font-semibold text-gray-800 w-[50%]">Description</th>
              <th className="py-2 text-right text-sm font-semibold text-gray-800 w-[15%]">Quantity</th>
              <th className="py-2 text-right text-sm font-semibold text-gray-800 w-[15%]">Rate</th>
              <th className="py-2 text-right text-sm font-semibold text-gray-800 w-[15%]">Amount</th>
              <th className="py-2 w-[5%]"></th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index} className="border-b border-gray-100">
                <td className="py-2">
                  <Input
                    placeholder="Description"
                    value={item.description}
                    onChange={(e) =>
                      handleItemChange(index, "description", e.target.value)
                    }
                    className="border-dashed"
                  />
                </td>
                <td className="py-2">
                  <Input
                    type="number"
                    placeholder="Qty"
                    value={item.quantity}
                    onChange={(e) =>
                      handleItemChange(index, "quantity", e.target.value)
                    }
                    className="text-right border-dashed"
                  />
                </td>
                <td className="py-2">
                  <Input
                    type="number"
                    placeholder="Rate"
                    value={item.rate}
                    onChange={(e) =>
                      handleItemChange(index, "rate", e.target.value)
                    }
                    className="text-right border-dashed"
                  />
                </td>
                <td className="py-2 text-right text-gray-600">
                  {formatCurrency(item.quantity * item.rate, currency)}
                </td>
                <td className="py-2 text-center">
                  {items.length > 1 && (
                    <Button
                      type="button"
                      size="icon"
                      variant="ghost"
                      onClick={() => handleRemoveItem(index)}
                      className="h-8 w-8 text-gray-500 hover:text-red-500"
                    >
                      <Trash2 className="h-4 w-4" />
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Button
          type="button"
          variant="outline"
          onClick={handleAddItem}
          className="text-sm"
        >
          Add Line Item
        </Button>
      </div>
    </div>
  );
};


import { useState, useRef } from "react";
import { FileText } from "lucide-react";
import { InvoicePreview } from "@/components/invoice/InvoicePreview";
import { ContactInfo, LineItem, CurrencyCode } from "@/types/invoice";
import { Button } from "@/components/ui/button";
import html2pdf from "html2pdf.js";

const Index = () => {
  const [items, setItems] = useState<LineItem[]>([{ description: "", quantity: 1, rate: 0 }]);
  const [businessInfo, setBusinessInfo] = useState<ContactInfo>({
    name: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    phone: "",
    email: "",
  });
  const [clientInfo, setClientInfo] = useState<ContactInfo>({
    name: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    phone: "",
    email: "",
  });
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [logo, setLogo] = useState<string | null>(null);
  const [logoSize, setLogoSize] = useState(100);
  const [notes, setNotes] = useState("");
  const [terms, setTerms] = useState("");
  const [taxRate, setTaxRate] = useState(0);
  const [amountPaid, setAmountPaid] = useState(0);
  const [currency, setCurrency] = useState<CurrencyCode>("USD");
  const invoiceRef = useRef<HTMLDivElement>(null);

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container py-12">
        <header className="mb-12 space-y-4 animate-fade-in">
          <div className="flex justify-center items-center mb-6">
            <a 
              href="https://abcinvoice.com" 
              className="flex items-center"
            >
              <img 
                src="/lovable-uploads/5aac8ba0-a615-4fc6-8463-db3423ed7053.png" 
                alt="ABC Invoice Logo" 
                className="h-24 hover:opacity-90 transition-opacity"
                style={{ marginBottom: '50px' }}
              />
            </a>
          </div>
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight sm:text-5xl">
              Create Professional Invoices
            </h1>
            <p className="text-lg text-gray-600 max-w-2xl mx-auto">
              Generate beautiful, professional invoices in seconds. Edit directly on the preview below.
            </p>
          </div>
        </header>

        <div className="max-w-5xl mx-auto">
          <InvoicePreview 
            items={items}
            setItems={setItems}
            businessInfo={businessInfo}
            setBusinessInfo={setBusinessInfo}
            clientInfo={clientInfo}
            setClientInfo={setClientInfo}
            invoiceNumber={invoiceNumber}
            setInvoiceNumber={setInvoiceNumber}
            invoiceDate={invoiceDate}
            setInvoiceDate={setInvoiceDate}
            dueDate={dueDate}
            setDueDate={setDueDate}
            logo={logo}
            setLogo={setLogo}
            logoSize={logoSize}
            setLogoSize={setLogoSize}
            notes={notes}
            setNotes={setNotes}
            terms={terms}
            setTerms={setTerms}
            taxRate={taxRate}
            setTaxRate={setTaxRate}
            amountPaid={amountPaid}
            setAmountPaid={setAmountPaid}
            currency={currency}
            setCurrency={setCurrency}
            invoiceRef={invoiceRef}
          />
          
          <div className="flex justify-center mt-8">
            <Button
              onClick={() => {
                if (invoiceRef.current) {
                  // Generate a filename based on invoice number
                  const filename = invoiceNumber 
                    ? `Invoice-${invoiceNumber}.pdf` 
                    : 'invoice.pdf';
                    
                  html2pdf()
                    .set({
                      margin: 1,
                      filename: filename,
                      image: { type: 'jpeg', quality: 0.98 },
                      html2canvas: { scale: 2 },
                      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
                    })
                    .from(invoiceRef.current)
                    .save();
                }
              }}
              className="gap-2 text-white"
              style={{ backgroundColor: "#1F85FF" }}
            >
              <FileText className="h-4 w-4 text-white" />
              Generate PDF
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;

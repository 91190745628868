
export interface LineItem {
  description: string;
  quantity: number;
  rate: number;
}

export interface ContactInfo {
  name: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  phone: string;
  email: string;
}

export type CurrencyCode = "USD" | "EUR" | "GBP" | "CAD" | "AUD" | "JPY" | "CNY" | "INR" | "PKR" | "MXN" | "BRL" | "ZAR" | "RUB" | "SGD" | "HKD" | "CHF" | "SEK" | "NZD";

export interface CurrencyInfo {
  code: CurrencyCode;
  symbol: string;
}

export const CURRENCIES: Record<CurrencyCode, CurrencyInfo> = {
  USD: { code: "USD", symbol: "$" },
  PKR: { code: "PKR", symbol: "₨" },
  EUR: { code: "EUR", symbol: "€" },
  GBP: { code: "GBP", symbol: "£" },
  CAD: { code: "CAD", symbol: "CA$" },
  AUD: { code: "AUD", symbol: "A$" },
  JPY: { code: "JPY", symbol: "¥" },
  CNY: { code: "CNY", symbol: "¥" },
  MXN: { code: "MXN", symbol: "Mex$" },
  BRL: { code: "BRL", symbol: "R$" },
  ZAR: { code: "ZAR", symbol: "R" },
  RUB: { code: "RUB", symbol: "₽" },
  SGD: { code: "SGD", symbol: "S$" },
  HKD: { code: "HKD", symbol: "HK$" },
  CHF: { code: "CHF", symbol: "CHF" },
  SEK: { code: "SEK", symbol: "kr" },
  NZD: { code: "NZD", symbol: "NZ$" },
  INR: { code: "INR", symbol: "₹" }
};
